import React from "react";
import { Link } from "gatsby";

const items = [
  // { label: "Setup & Login, Controls/Items", to: "/stepbystep/" },
  { label: "Practice, Videos, and Reviews", to: "/problemscape/xpert-ypert/" },
  { label: "Chapter 1 Overview", to: "/problemscape/ch-1-overview/" },
  { label: "Chapter 2 Overview", to: "/problemscape/ch-2-overview/" },
  { label: "Chapter 3 Overview", to: "/problemscape/ch-3-overview/" },
  { label: "Chapter 4 Overview", to: "/problemscape/ch-4-overview/" },
];

function ProblemScapeGameHeader() {
  return (
    <section
      className="Index-page"
      data-collection-id="606c92eba800ce1d831d9d96"
      data-edit-main-image="Background"
    >
      <div
        className="Index-page-scroll-indicator"
        data-controller="ScrollIndicator"
        data-controllers-bound="ScrollIndicator"
      >
        <div className="Index-page-scroll-indicator-text">Scroll</div>
        <svg
          className="Index-page-scroll-indicator-arrow Icon Icon--caretLarge--down"
          viewBox="0 0 48 23"
        >
          <use xlinkHref="/assets/ui-icons.svg#caret-down-large-icon"></use>
        </svg>
        <div className="Index-page-scroll-indicator-line"></div>
      </div>
      <div className="Index-page-content ">
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          data-type="page"
          id="page-606c92eba800ce1d831d9d96"
        >
          <div className="row sqs-row">
            <div className="col sqs-col-12">
              <div className="row sqs-row">
                {items.map((item) => (
                  <div key={item.label} className="col sqs-col-2 span-2">
                    <div className="sqs-block button-block sqs-block-button">
                      <div className="sqs-block-content">
                        <div
                          className="sqs-block-button-container sqs-block-button-container--center"
                          data-animation-role="button"
                          data-alignment="center"
                          data-button-size="small"
                          data-button-type="tertiary"
                        >
                          <Link
                            to={item.to}
                            className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element"
                            data-initialized="true"
                          >
                            {item.label}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProblemScapeGameHeader;
