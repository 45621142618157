import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";

function ProblemScapeXpertYpertPage() {
  return (
    <>
      <ProblemScapeGameHeader />
      <section
        id="xpertypert"
        className="Index-page"
        data-collection-id="606c8e5d5058b2536ae517d9"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-content "
          id="yui_3_17_2_1_1671304180297_1908"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-606c8e5d5058b2536ae517d9"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671304180297_1907">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671304180297_1906"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-bded1de94b25bd23da51"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      Practice and Worked Examples in Xpert Notebook
                    </h2>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      The Xpert Notebook is picked up by the student at the
                      beginning of the game and is always available to the
                      student. It contains a worked examples page and a
                      multi-level practice component for each of the concepts
                      that is covered in the game.
                    </p>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_1905"
                >
                  <div className="col sqs-col-6">
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-ddec4f2abb69ff167284"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_1928"
                      >
                        <div
                          className="image-block-outer-wrapper
                            layout-caption-below
                            design-layout-inline
                            combination-animation-none
                            individual-animation-none
                            individual-text-animation-none
                          sqs-narrow-width"
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_1927"
                        >
                          <StaticImage
                            src="../../images/problemscape/notebook.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-6">
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                    >
                      <div className="sqs-block-content">
                        <div
                          className="image-block-outer-wrapper
                            layout-caption-below
                            design-layout-inline
                            combination-animation-none
                            individual-animation-none
                            individual-text-animation-none
                          sqs-narrow-width"
                          data-test="image-block-inline-outer-wrapper"
                        >
                          <StaticImage
                            src="../../images/problemscape/notebook-2.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sqs-block html-block sqs-block-html">
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      YPERT VIDEOS
                    </h2>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_1968"
                >
                  <div className="col sqs-col-6 span-6">
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-615ca10c8f11ada2a241"
                    >
                      <div className="sqs-block-content">
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          The YPert diary pages are scattered around the grounds
                          of Arithma and have videos that explain the ‘why’ for
                          certain mathematical processes and concepts. For
                          example, why is there an order of operations and why
                          do we only add like terms.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_1967"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-92495050c6235af73b95"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_1966"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_1965"
                        >
                          <StaticImage
                            src="../../images/problemscape/diaries.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sqs-block html-block sqs-block-html">
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      REVIEW
                    </h2>
                  </div>
                </div>
                <div className="row sqs-row">
                  <div className="col sqs-col-6 span-6">
                    <div className="sqs-block image-block sqs-block-image sqs-text-ready">
                      <div className="sqs-block-content">
                        <div
                          className="
                            image-block-outer-wrapper
                            layout-caption-below
                            design-layout-inline
                            combination-animation-none
                            individual-animation-none
                            individual-text-animation-none
                          "
                          data-test="image-block-inline-outer-wrapper"
                        >
                          <StaticImage src="../../images/reviews.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-6 span-6">
                    <div className="sqs-block html-block sqs-block-html">
                      <div className="sqs-block-content">
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          Each chapter in the adventure is followed by a Review
                          page that students have to complete to unlock the next
                          chapter of the adventure. The spaced reviews help to
                          solidify what students have been learning through out
                          the game. Students are rewarded with a potion to
                          evolve their pet sniffler for completing the reviews.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Head() {
  return <Seo title="Practice, Videos & Reviews" />;
}

export default ProblemScapeXpertYpertPage;
